<template>
	<ClientOnly>
		<MoleculesOutBrain v-if="adId && showAds.getShowAds()" :adId="adId" :adWidgetId="adWidgetId" :outBrainUrl="outBrainUrl" />
	</ClientOnly>
</template>

<script setup lang="ts">
	import { ref, onMounted } from "vue";
	import { useShowAds } from "~/composables/useShowAds";

	const props = defineProps<{
		adId: string;
		type?: string;
		articleType?: string;
		outbrain_code?: string;
	}>();

	const isOutbrainScriptLoaded = useIsOutbrainLoaded();

	const currentUrl = useRequestURL();
	const showAds = await useShowAds();
	const outBrainUrl = currentUrl.origin.replace(".local", ".de") + currentUrl.pathname; // Muss ohne Parameter eingelesen werden

	const adType = ref<string>("");
	const adWidgetId = ref("AR_11");
	const userDataCookie = useUserDataFromCookie();

	const userType = ref("anonymous");
	const isPlusUser = ref(false);
	const OB_Context = ref("");

	if (userDataCookie?.userType) {
		userType.value = userDataCookie.userType;
	}

	const outbrainMapping: any = {
		"1": "recommendation",
		"11": "free",
		"22": "plus",
		"33": "erstes",
		"34": "zweites",
	};

	if (props.outbrain_code) {
		adType.value = outbrainMapping[props.outbrain_code] ? outbrainMapping[props.outbrain_code] : "free";
	}

	if (props.articleType) {
		adType.value = props.articleType;
	}

	declare global {
		interface Window {
			isPlusUser: boolean;
			OB_ContextKeyValue: string;
		}
	}

	onMounted(() => {
		let view = "desktop";
		let mobile = window.matchMedia("(max-width: 728px)");

		if (mobile.matches) {
			view = "mobile";
		}

		if (view === "mobile") {
			if (adType.value === "free" || adType.value === "plus") {
				adWidgetId.value = "MB_11";
			}

			if (adType.value === "erstes") {
				adWidgetId.value = "MB_33";
			}

			if (adType.value === "zweites") {
				adWidgetId.value = "MB_34";
			}
		}

		if (view === "desktop") {
			if (adType.value === "free" || adType.value === "plus") {
				adWidgetId.value = "AR_11";
			}

			if (adType.value === "erstes") {
				adWidgetId.value = "AR_33";
			}

			if (adType.value === "zweites") {
				adWidgetId.value = "AR_34";
			}
		}

		if (props.type === "recommendation") {
			adWidgetId.value = "AR_1";
		}

		if (userType.value === "premium" || userType.value === "internal") {
			isPlusUser.value = true;
			OB_Context.value = "plusUser";

			if (typeof window !== "undefined") {
				window.isPlusUser = isPlusUser.value;
				window.OB_ContextKeyValue = OB_Context.value;
			}
		}
		if (window.isCMPLoaded) {
			triggerOutbrainScript();
		} else {
			document.addEventListener("cmp.consentDataSet", triggerOutbrainScript);
		}
		document.addEventListener("cmp.consentDataUpdate", triggerOutbrainScript);
	});

	function triggerOutbrainScript() {
		if (showAds.getShowAds().value && !isOutbrainScriptLoaded.value) {
			useHead({
				script: [{ src: "https://widgets.outbrain.com/outbrain.js", async: true, tagPriority: 2 }],
			});
			isOutbrainScriptLoaded.value = true;
		}
	}
</script>
